exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adres-i-kontakty-js": () => import("./../../../src/pages/adres-i-kontakty.js" /* webpackChunkName: "component---src-pages-adres-i-kontakty-js" */),
  "component---src-pages-cookie-js": () => import("./../../../src/pages/cookie.js" /* webpackChunkName: "component---src-pages-cookie-js" */),
  "component---src-pages-dostavka-i-oplata-js": () => import("./../../../src/pages/dostavka-i-oplata.js" /* webpackChunkName: "component---src-pages-dostavka-i-oplata-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-korzina-js": () => import("./../../../src/pages/korzina.js" /* webpackChunkName: "component---src-pages-korzina-js" */),
  "component---src-pages-korzina-order-js": () => import("./../../../src/pages/korzina/order.js" /* webpackChunkName: "component---src-pages-korzina-order-js" */),
  "component---src-pages-korzina-order-order-processed-js": () => import("./../../../src/pages/korzina/order/order-processed.js" /* webpackChunkName: "component---src-pages-korzina-order-order-processed-js" */),
  "component---src-pages-korzina-order-order-success-js": () => import("./../../../src/pages/korzina/order/order-success.js" /* webpackChunkName: "component---src-pages-korzina-order-order-success-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-offer-js": () => import("./../../../src/pages/offer.js" /* webpackChunkName: "component---src-pages-offer-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-sale-js": () => import("./../../../src/pages/sale.js" /* webpackChunkName: "component---src-pages-sale-js" */),
  "component---src-pages-special-menu-js": () => import("./../../../src/pages/special-menu.js" /* webpackChunkName: "component---src-pages-special-menu-js" */),
  "component---src-pages-vacancy-js": () => import("./../../../src/pages/vacancy.js" /* webpackChunkName: "component---src-pages-vacancy-js" */),
  "component---src-templates-kombo-teamplates-js": () => import("./../../../src/templates/komboTeamplates.js" /* webpackChunkName: "component---src-templates-kombo-teamplates-js" */),
  "component---src-templates-products-teamplate-js": () => import("./../../../src/templates/productsTeamplate.js" /* webpackChunkName: "component---src-templates-products-teamplate-js" */),
  "component---src-templates-sety-teampletes-js": () => import("./../../../src/templates/setyTeampletes.js" /* webpackChunkName: "component---src-templates-sety-teampletes-js" */)
}

